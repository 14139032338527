import { Box, FormControlLabel, FormControlLabelProps, Radio, RadioProps, styled } from "@mui/material";
import { setDefaultOptions } from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { localeMap } from "src/utils/localeMap";

type Props = {
  disabled: boolean;
};

/**
 * 言語選択
 *
 * 現在は言語切替時にこのコンポーネント内で LocalStorage の lang の値を書き換えるので注意
 */
export const LanguageSelect = ({ disabled }: Props) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
      <LangButton disabled={disabled} lang="ja" label={"日本語"} />
      <LangButton disabled={disabled} lang="en" label={"ENGLISH"} />
    </Box>
  );
};

const RadioLabel = styled(FormControlLabel)<FormControlLabelProps>((props) => ({
  boxSizing: "border-box",
  margin: 0,
  minHeight: "56px",
  borderRadius: "4px",
  border: "1px solid rgba(0, 0, 0, 0.3)",
  ":hover": {
    borderColor: "#000",
  },
}));

const RadioControl = styled(Radio)<RadioProps>(() => ({
  "&.Mui-checked": {
    color: "#F29500",
  },
}));

type LangButtonProps = { disabled: boolean; lang: string; label: string };

const LangButton = ({ disabled, lang, label }: LangButtonProps) => {
  const { i18n } = useTranslation();
  const checked = useMemo(() => i18n.language === lang, [lang, i18n.language]);
  const handleClick = useCallback(() => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    setDefaultOptions({ locale: localeMap[lang as keyof typeof localeMap] });
  }, [lang, i18n]);
  return (
    <RadioLabel disabled={disabled} checked={checked} control={<RadioControl />} label={label} onClick={handleClick} />
  );
};
