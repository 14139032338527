import { useEffect, useState } from "react";
import { Box, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import LogoImg from "../../assets/images/Helppad_common_logo.png";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "src/store/helppadApi";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { LanguageSelect } from "src/modules/parts/LanguageSelect";

export const LoginSP = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  // ログイン用パラメータ
  const presetId = localStorage.getItem("facility_login_id");
  const [facilityLoginId, setFacilityLoginId] = useState(presetId == null ? "" : presetId);
  const [staffLoginId, setStaffLoginId] = useState("");
  const [staffPassword, setStaffPassword] = useState("");

  // Modalの制御用
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [passForgetModalOpen, setPassForgetModalOpen] = useState(false);
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };
  const closePassForgetModal = () => {
    setPassForgetModalOpen(false);
  };

  const [login, { isError, isSuccess, isLoading }] = useLoginMutation();

  // パスワード可視化
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Loginボタン押下時の処理
  const onClickLogin = async () => {
    login({
      modelsLoginIn: {
        facility_login_id: facilityLoginId,
        staff_login_id: staffLoginId,
        staff_password: staffPassword,
      },
    });
    localStorage.setItem("facility_login_id", facilityLoginId);
  };

  useEffect(() => {
    if (isSuccess) {
      navigation("/notices");
    }
  }, [isSuccess, navigation]);

  useEffect(() => {
    setErrorModalOpen(isError);
  }, [isError]);

  return (
    <>
      {/* ログイン用フォーム */}
      <Box p={2} display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box alignItems="center" display="flex" justifyContent="center" py={3}>
            <img src={LogoImg} height="40" width="200" alt="" />
          </Box>
          <Grid container alignItems="center" rowGap={3} width="100%" mb={5}>
            <Box width="100%" borderBottom="1px solid #E6E6E6" mb={2}>
              <Typography
                py={2}
                variant="h1"
                fontSize={26}
                fontWeight="bold"
                display="inline-block"
                borderBottom="2px solid #F29500"
              >
                {t("common.button.login")}
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Typography textAlign="left" mb={1}>
                {t("login.login_sp.facility_id")}
              </Typography>
              <TextField
                disabled={isLoading}
                placeholder={t("login.login_sp.facility_id_label")}
                fullWidth
                value={facilityLoginId}
                onChange={(event) => setFacilityLoginId(event.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign="left" mb={1}>
                {t("common.staff.staff_id")}
              </Typography>
              <TextField
                disabled={isLoading}
                placeholder={t("login.login_sp.staff_id_label")}
                fullWidth
                value={staffLoginId}
                onChange={(event) => setStaffLoginId(event.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign="left" mb={1}>
                {t("login.login_sp.password")}
              </Typography>
              <TextField
                disabled={isLoading}
                type={showPassword ? "text" : "password"}
                placeholder={t("login.login_sp.password_label")}
                fullWidth
                value={staffPassword}
                onChange={(event) => setStaffPassword(event.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign="left" mb={1}>
                言語 / Language
              </Typography>
              <LanguageSelect disabled={isLoading} />
            </Grid>
          </Grid>
          <Button
            disabled={isLoading}
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: "white",
              backgroundColor: "#F29500",
              width: "100%",
              height: 60,
              maxWidth: "250px",
              borderRadius: 30,
              "&:hover": {
                backgroundColor: "#FDB864",
              },
            }}
            variant="contained"
            onClick={onClickLogin}
            disableElevation
          >
            {t("common.button.login")}
          </Button>
          <Box display="flex" textAlign="center" my={3} sx={{ width: 300 }}>
            <NavLink
              style={() => ({
                color: "#F29500",
                alignItems: "center",
                textAlign: "center",
                fontSize: 18,
                width: "100%",
              })}
              to="/login"
              onClick={() => {
                setPassForgetModalOpen(true);
              }}
            >
              {t("login.login_sp.forgot_password")}
            </NavLink>
          </Box>
        </Box>
      </Box>
      {/* ログインエラー用モーダル */}
      <Modal open={errorModalOpen} onClose={closeErrorModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: 400, mx: 2, bgcolor: "background.paper", p: 5, borderRadius: 2 }}>
            <Typography my={2} fontSize={24} textAlign="center">
              {t("login.login_sp.login_error")}
            </Typography>
            <Typography my={1} fontWeight="bold" textAlign="center" color="#F29500">
              {t("login.login_sp.different_id_password")}
            </Typography>
            <Typography fontSize={13} textAlign="center">
              ※{t("login.login_sp.if_forgot_password")}
            </Typography>
            <Box mt={2} justifyContent="center" display="flex">
              <Box display="flex" justifyContent="center" mt={1} sx={{ width: 250 }}>
                <Button
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    backgroundColor: "#606060",
                    width: 150,
                    height: 50,
                    borderRadius: 25,
                    "&:hover": {
                      backgroundColor: "#a0a0a0",
                    },
                  }}
                  variant="contained"
                  onClick={closeErrorModal}
                  disableElevation
                >
                  {t("common.button.close")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* パスワードをお忘れの方押下時モーダル */}
      <Modal open={passForgetModalOpen} onClose={closePassForgetModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: 400, mx: 2, bgcolor: "background.paper", p: 5, borderRadius: 2 }}>
            <Typography my={1} fontWeight="bold" textAlign="center">
              {t("login.login_sp.if_forgot_password_2")}
            </Typography>
            <Box mt={2} justifyContent="center" display="flex">
              <Box display="flex" justifyContent="center" mt={1} sx={{ width: 250 }}>
                <Button
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    backgroundColor: "#606060",
                    width: 150,
                    height: 50,
                    borderRadius: 25,
                    "&:hover": {
                      backgroundColor: "#a0a0a0",
                    },
                  }}
                  variant="contained"
                  onClick={closePassForgetModal}
                  disableElevation
                >
                  {t("common.button.close")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
