import { enUS, ja } from "date-fns/locale";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const localeMap = {
  en: enUS,
  ja: ja,
} as const;
type LocaleType = keyof typeof localeMap;

/**
 * i18n の言語と date-fns の locale を紐づけるためのもの。
 * 主に mui の LocalizationProvider で利用する想定で、基本的には使う必要無し。
 * デフォルトは en
 *
 * @memo 対応言語が増えたら localeMap を追加修正する必要あり
 */
export function useLocale() {
  const { i18n } = useTranslation();
  const { language } = i18n;

  return useMemo(() => localeMap[language as LocaleType] ?? localeMap.en, [language]);
}
