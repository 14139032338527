import { PropsWithChildren, useEffect, useState } from "react";
import { Box, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import LogoImg from "../../assets/images/Helppad_common_logo.png";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "src/store/helppadApi";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { LanguageSelect } from "src/modules/parts/LanguageSelect";

export const LoginPC = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  // ログイン用パラメータ
  const presetId = localStorage.getItem("facility_login_id");
  const [facilityLoginId, setFacilityLoginId] = useState(presetId == null ? "" : presetId);
  const [staffLoginId, setStaffLoginId] = useState("");
  const [staffPassword, setStaffPassword] = useState("");

  // Modalの制御用
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [passForgetModalOpen, setPassForgetModalOpen] = useState(false);
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };
  const closePassForgetModal = () => {
    setPassForgetModalOpen(false);
  };

  const [login, { isError, isSuccess, isLoading }] = useLoginMutation();

  // パスワード可視化
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Loginボタン押下時の処理
  const onClickLogin = async () => {
    login({
      modelsLoginIn: {
        facility_login_id: facilityLoginId,
        staff_login_id: staffLoginId,
        staff_password: staffPassword,
      },
    });
    localStorage.setItem("facility_login_id", facilityLoginId);
  };

  useEffect(() => {
    if (isSuccess) {
      navigation("/notices");
    }
  }, [isSuccess, navigation]);

  useEffect(() => {
    setErrorModalOpen(isError);
  }, [isError]);

  return (
    <>
      {/* ログイン用フォーム */}
      <Box display="flex" height="100%" width="100%">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" pb={11}>
          <Box sx={{ mb: "48px" }}>
            <img src={LogoImg} height="64" width="355" alt="logo" />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" maxWidth="560px">
            <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={3} mb={6}>
              {/* 施設ID */}
              <InputGroup label={t("login.login_pc.facility_id")}>
                <TextField
                  disabled={isLoading}
                  placeholder={t("login.login_pc.facility_id_label")}
                  fullWidth
                  value={facilityLoginId}
                  onChange={(event) => setFacilityLoginId(event.target.value)}
                  required
                />
              </InputGroup>
              {/* スタッフID */}
              <InputGroup label={t("common.staff.staff_id")}>
                <TextField
                  disabled={isLoading}
                  placeholder={t("login.login_pc.staff_id_label")}
                  fullWidth
                  value={staffLoginId}
                  onChange={(event) => setStaffLoginId(event.target.value)}
                  required
                />
              </InputGroup>
              {/* パスワード */}
              <InputGroup label={t("login.login_pc.password")}>
                <TextField
                  disabled={isLoading}
                  type={showPassword ? "text" : "password"}
                  placeholder={t("login.login_pc.password_label")}
                  fullWidth
                  value={staffPassword}
                  onChange={(event) => setStaffPassword(event.target.value)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </InputGroup>
              {/* 言語 */}
              {/* 言語選択になるため、ここは言語ファイルを利用しない */}
              <InputGroup label={"言語 / Language"}>
                <LanguageSelect disabled={isLoading} />
              </InputGroup>
            </Box>
            <Button
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#F29500",
                width: "100%",
                height: 60,
                borderRadius: 30,
                "&:hover": {
                  backgroundColor: "#FDB864",
                },
              }}
              disabled={isLoading}
              variant="contained"
              onClick={onClickLogin}
              disableElevation
            >
              {t("common.button.login")}
            </Button>
            <Box display="flex" textAlign="center" mt={2} sx={{ width: 500 }}>
              <NavLink
                style={() => ({
                  color: "#F29500",
                  alignItems: "center",
                  textAlign: "center",
                  fontSize: 18,
                  width: "100%",
                })}
                to="/login"
                onClick={() => {
                  setPassForgetModalOpen(true);
                }}
              >
                {t("login.login_pc.forgot_password")}
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* ログインエラー用モーダル */}
      <Modal open={errorModalOpen} onClose={closeErrorModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: 400, bgcolor: "background.paper", p: 5, borderRadius: 2 }}>
            <Typography my={2} fontSize={24} textAlign="center">
              {t("login.login_pc.login_error")}
            </Typography>
            <Typography my={1} fontWeight="bold" textAlign="center" color="#F29500">
              {t("login.login_pc.different_id_password")}
            </Typography>
            <Typography fontSize={13} textAlign="center">
              ※{t("login.login_pc.if_forgot_password")}
            </Typography>
            <Box mt={2} justifyContent="center" display="flex">
              <Box display="flex" justifyContent="center" mt={1} sx={{ width: 250 }}>
                <Button
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    backgroundColor: "#606060",
                    width: 150,
                    height: 50,
                    borderRadius: 25,
                    "&:hover": {
                      backgroundColor: "#a0a0a0",
                    },
                  }}
                  variant="contained"
                  onClick={closeErrorModal}
                  disableElevation
                >
                  {t("common.button.close")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* パスワードをお忘れの方押下時モーダル */}
      <Modal open={passForgetModalOpen} onClose={closePassForgetModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: 400, bgcolor: "background.paper", p: 5, borderRadius: 2 }}>
            <Typography my={1} fontWeight="bold" textAlign="center">
              {t("login.login_pc.if_forgot_password_2")}
            </Typography>
            <Box mt={2} justifyContent="center" display="flex">
              <Box display="flex" justifyContent="center" mt={1} sx={{ width: 250 }}>
                <Button
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    backgroundColor: "#606060",
                    width: 150,
                    height: 50,
                    borderRadius: 25,
                    "&:hover": {
                      backgroundColor: "#a0a0a0",
                    },
                  }}
                  variant="contained"
                  onClick={closePassForgetModal}
                  disableElevation
                >
                  {t("common.button.close")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

type InputProps = PropsWithChildren<{
  label: string;
}>;

const InputGroup = ({ label, children }: InputProps) => {
  return (
    <Box width={"100%"}>
      <Typography mb={1}>{label}</Typography>
      <Box width={"100%"}>{children}</Box>
    </Box>
  );
};
